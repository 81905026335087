import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import Loading from '../../common/components/Loading'
import NoContent from '../../common/components/NoContent'
import Paginate from '../../common/components/Paginate'
import {sttPage} from '../../common/utils/Utils'
import { Tag } from "antd";
import ActionTable from "../../common/components/actionTable/ActionTable";
import InputSearch from "../../common/components/form/InputSearch";
import {dateUtils} from "../../common/utils/DateUtils";
import {approvedPackageStore} from "./ApprovedPackageStore";
import PopupApprovePackage from "./components/PopupApprove";
import PopupRejectPackage from "./components/PopupReject";
import {numberUtil} from "../../common/utils/NumberUtil";
import SelectAnt from "../../common/components/form/SelectAnt";
import PopupCancelApprovePackage from "./components/PopupCancel";
import DetailApprovePackage from "./components/DetailApprovePackage";


export const convertState = (state: string) => {
    switch (state) {
        case "APPROVED":
            return <Tag bordered={false} className="fs-14 text-center pe-2 ps-2" color="#efffef"
                        style={{width: 82, color: '#158212', borderRadius: 12}}>Đã duyệt</Tag>
        case "REJECTED":
            return <Tag bordered={false} className="fs-14 text-center pe-2 ps-2" color="#FFEEEF"
                        style={{width: 82, color: '#E3000F', borderRadius: 12}}>Từ chối</Tag>
        case "PENDING":
            return <Tag bordered={false} className="fs-14 text-center pe-2 ps-2" color="#E6E6E6"
                        style={{width: 82, color: '#808089', borderRadius: 12}}>Chờ duyệt</Tag>
        case "CANCELED":
            return <Tag bordered={false} className="fs-14 text-center pe-2 ps-2" color="#1F3A581A"
                        style={{width: 82, color: '#1F3A58', borderRadius: 12}}>Hủy</Tag>
    }
}

export const convertTypeDate = (type: string) => {
    switch (type) {
        case "DAY":
            return "Ngày"
        case "YEAR":
            return "Năm"
        case "MONTH":
            return "Tháng"
    }
}

const ApprovedPackagePage = () => {

    const handlePageClick = async (data: any) => {
        approvedPackageStore.page = data.selected
        await approvedPackageStore.getApprovedPackage()
    }

    useEffect(() => {
        approvedPackageStore.getApprovedPackage().then()
    }, [])

    const callApi = () => {
        approvedPackageStore.page = 0
        approvedPackageStore.getApprovedPackage().then()
    }


    return (
        <div className="enterprise_page">
            <div className='d-flex align-items-center justify-content-between mb-6'>
                <h1 className='d-flex color-005CF7 fw-bolder fs-3 align-items-center my-1'>Yêu cầu nâng cấp</h1>
            </div>
            <div className="d-flex mb-4 align-items-center">
                <InputSearch value={approvedPackageStore.keyword} callApi={() => callApi()} onChange={(e: any) => approvedPackageStore.keyword = e.currentTarget.value}/>
                <SelectAnt width={220} isShowClear value={approvedPackageStore.status} onChange={(e: any) => {
                    approvedPackageStore.status = e
                    callApi()
                }} placeholder="Trạng thái" options={approvedPackageStore.listStatus}/>
            </div>
            <div id='kt_ecommerce_sales_table_wrapper' className='dataTables_wrapper dt-bootstrap4 no-footer'>
                {approvedPackageStore.isLoading ? <Loading/> : <div className='table-responsive'>
                    {approvedPackageStore.listApprovedPackage && approvedPackageStore.listApprovedPackage.length > 0 ?
                        <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th>STT</th>
                                <th>Ngày gửi</th>
                                <th>Mã thanh toán</th>
                                <th>Người gửi</th>
                                <th>Tên gói</th>
                                <th>Loại</th>
                                <th>Tổng tiền</th>
                                <th className="text-center">Trạng thái</th>
                                <th>Ngày duyệt</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {approvedPackageStore.listApprovedPackage.map((item: any, i: number) => {
                                return (
                                    <tr key={i}>
                                        <td>{sttPage(approvedPackageStore.page, i)}</td>
                                        <td>{dateUtils.formatDate(item.createdAt)}</td>
                                        <td>{item.code}</td>
                                        <td>{item.user?.name}</td>
                                        <td>{item.packageData?.name}</td>
                                        <td>{item.packagePriceData?.validityPeriod} {convertTypeDate(item.packagePriceData.timeUnit)}</td>
                                        <td>{numberUtil.number_format(item.price)}</td>
                                        <td className="text-center">
                                            {convertState(item.status)}
                                        </td>
                                        <td>{dateUtils.formatDate(item.approvedAt)}</td>
                                        <td width='10%'>
                                            <ActionTable
                                                data={[
                                                    {
                                                        name: "Chi tiết",
                                                        type: "detail",
                                                        dataTarget: 'detail_approve_package',
                                                        action: () => approvedPackageStore.getDetail(item.id),
                                                    },
                                                    {
                                                        name: "Duyệt",
                                                        type: "success",
                                                        isHide: item.status !== "PENDING",
                                                        dataTarget: 'popup_approve',
                                                        action: () => approvedPackageStore.id = item.id,
                                                    },
                                                    {
                                                        name: "Từ chối",
                                                        type: "cancel",
                                                        isHide: item.status !== "PENDING",
                                                        dataTarget: 'popup_reject',
                                                        action: () => approvedPackageStore.id = item.id,
                                                    }
                                                ]}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                    <Paginate callback={handlePageClick} totalPage={approvedPackageStore.totalPage}
                              currentPage={approvedPackageStore.page}/>
                    <PopupApprovePackage/>
                    <PopupRejectPackage/>
                    <PopupCancelApprovePackage/>
                    <DetailApprovePackage/>
                </div>}
            </div>
        </div>
    )
}

export default observer(ApprovedPackagePage)