import React from 'react';
import {useDebouncedCallback} from "use-debounce";


interface IProps {
    value: string
    onChange: (e: any) => void
    callApi: () => void
}

const InputSearch = ({value, onChange, callApi}: IProps) => {

    const handleSearch = useDebouncedCallback(() => {
        callApi()
    }, 1000);

    return (
        <div className='d-flex align-items-center position-relative my-1 me-6'>
            <i className='bi bi-search cursor-pointer fs-1x fs-3 position-absolute ms-4'
               onClick={() => callApi()}/>
            <input type='text' value={value} style={{border: '1px solid #d9d9d9'}}
                   onChange={(e: any) => {
                       onChange(e)
                       handleSearch()
                   }}
                   onKeyDown={(e: any) => {
                       if (e.key === 'Enter') {
                           callApi()
                       }
                   }} data-kt-ecommerce-order-filter='search'
                   className='form-control form-control-solid bg-white w-250px ps-12'
                   placeholder='Tìm kiếm '/>
        </div>
    );
}

export default InputSearch;