import {
  deleteRequest,
  getRequest,
  IApiResponse, patchRequest,
  postRequest,
  putRequest
} from '../../common/helpers/RequestHelper'
import {packageStore} from "./PackageStore";


class PackageService {

  public getPackage(): Promise<IApiResponse>{
    return getRequest(`/iss_365_user/package/filter?page=${packageStore.page}&size=10${packageStore.name?.trim() ? `&name=${packageStore.name?.trim()}` : ''}${packageStore.type ? `&type=${packageStore.type}` : ''}`)
  }

  public getDetail(id: any): Promise<IApiResponse>{
    return getRequest(`/iss_365_user/package/${id}`)
  }

  public add(data: object): Promise<IApiResponse>{
    return postRequest(`/iss_365_user/package`, data)
  }

  public edit(id: any, data: object): Promise<IApiResponse>{
    return putRequest(`/iss_365_user/package/${id}`, data)
  }

  public changeState(id: any, isActive: boolean): Promise<IApiResponse>{
    return patchRequest(`/iss_365_user/package/${id}/change-state?isActive=${isActive}`, {})
  }

  public changeRecommended(id: any): Promise<IApiResponse>{
    return patchRequest(`/iss_365_user/package/${id}/set-recommended`, {})
  }

  public delete(id: any): Promise<IApiResponse>{
    return deleteRequest(`/iss_365_user/package/${id}`, {})
  }


}

export const packageService = new PackageService()

