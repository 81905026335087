import {getRequest, IApiResponse, patchRequest} from '../../common/helpers/RequestHelper'
import {enterpriseStore} from "./EnterpriseStore";


class EnterpriseService {

  public getEnterprise(): Promise<IApiResponse>{
    return getRequest(`/iss_365_data/enterprise?page=${enterpriseStore.page}&size=10${enterpriseStore.keyword?.trim() ? `&keyword=${enterpriseStore.keyword?.trim()}` : ''}${enterpriseStore.state ? `&state=${enterpriseStore.state}` : ''}`)
  }

  public getDetail(id: any): Promise<IApiResponse>{
    return getRequest(`/iss_365_data/enterprise/${id}`)
  }

  public changeState(id: any, state: string): Promise<IApiResponse>{
    return patchRequest(`/iss_365_data/enterprise/${id}/state/${state}`, {})
  }


}

export const enterpriseService = new EnterpriseService()

