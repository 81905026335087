import React from 'react'
import {observer} from 'mobx-react-lite'
import {numberUtil} from "../../../common/utils/NumberUtil";
import {approvedPackageStore} from "../ApprovedPackageStore";
import {convertTypeDate} from "../ApprovedPackagePage";
import {number_format} from "../../../common/utils/Utils";

enum IType {
    GOVERNMENT = "GOVERNMENT",
    ENTERPRISE = "ENTERPRISE",
    INDIVIDUAL = "INDIVIDUAL",
}

const convertTypeEnterprise = (type:string) => {
  switch (type) {
      case IType.ENTERPRISE:
          return "Doanh nghiệp"
      case IType.INDIVIDUAL:
          return "Cá nhân"
      case IType.GOVERNMENT:
          return "Chính phủ"
  }
}



const DetailApprovePackage = () => {


    let {packageData, bill, customer, code, packagePriceData} = approvedPackageStore.dataDetail

    return (
        <div className='modal fade' id='detail_approve_package' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered max-w-1380px'>
                <div className='modal-content rounded-4px overflow-hidden'>
                    <div className='modal-header'>
                        <h2 className="color-005CF7">Chi tiết</h2>
                        <button type="button" id="close_package" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"/>
                    </div>
                    <div className='modal-body bg-F3F4F5 py-lg-8 px-lg-8'>
                        <div className="row">
                            <div className="col-8">
                                <div className="bg-white rounded-8px mb-10px p-16px">
                                    <h4 className="fs-16px fw-500 color-005CF7 mb-10px">1. Gói sản phẩm đã chọn</h4>
                                    <div className="table-responsive p-0 border">
                                        <table className="table align-middle table-hover gy-7 gs-7 mb-0">
                                            <thead>
                                            <tr className="bg-F2F3F5">
                                                <th className="text-center">STT</th>
                                                <th>Sản phẩm/ Dịch vụ</th>
                                                <th>ĐVT</th>
                                                <th>Loại</th>
                                                <th className="text-end">Thành tiền (VNĐ)</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td width="10%" className="text-center">{1}</td>
                                                <td>{packageData?.name}</td>
                                                <td>Gói</td>
                                                <td>{packagePriceData?.validityPeriod} {convertTypeDate(packagePriceData?.timeUnit)}</td>
                                                <td className="text-end">{numberUtil.number_format(packagePriceData?.price)}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="bg-white rounded-8px mb-10px p-16px">
                                    <h4 className="fs-16px fw-500 color-005CF7 mb-20px">2. Thông tin người mua hàng</h4>
                                    <div className="d-flex gap-2 align-items-center justify-content-between">
                                        <div className="fs-14px color-272E3B">Họ và tên</div>
                                        <div className="fs-14px fw-500 color-272E3B">{customer?.name}</div>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center justify-content-between">
                                        <div className="fs-14px color-272E3B">Số điện thoại</div>
                                        <div className="fs-14px fw-500 color-272E3B">{customer?.phone}</div>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center justify-content-between">
                                        <div className="fs-14px color-272E3B">Email nhận mã kích hoạt & giấy phép sử dụng</div>
                                        <div className="fs-14px fw-500 color-272E3B">{customer?.email}</div>
                                    </div>
                                </div>
                                <div className="bg-white rounded-8px mb-10px p-16px">
                                    <h4 className="fs-16px fw-500 color-005CF7 mb-10px">3. Đối tượng nhận hóa đơn</h4>
                                    <div className="d-flex gap-2 align-items-center justify-content-between">
                                        <div className="fs-14px color-272E3B">Đối tượng nhận hóa đơn</div>
                                        <div className="fs-14px fw-500 color-272E3B">{convertTypeEnterprise(bill?.type)}</div>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center justify-content-between">
                                        <div className="fs-14px color-272E3B">Tên đơn vị</div>
                                        <div className="fs-14px fw-500 color-272E3B">{bill?.name}</div>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center justify-content-between">
                                        <div className="fs-14px color-272E3B">Mã số thuế</div>
                                        <div className="fs-14px fw-500 color-272E3B">{bill?.tax}</div>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center justify-content-between">
                                        <div className="fs-14px color-272E3B">Địa chỉ</div>
                                        <div className="fs-14px fw-500 color-272E3B">{bill?.address?.detail}, {bill?.address?.ward?.name}, {bill?.address?.district?.name}, {bill?.address?.province?.name} </div>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center justify-content-between">
                                        <div className="fs-14px color-272E3B">Email nhận hóa đơn</div>
                                        <div className="fs-14px fw-500 color-272E3B">{bill?.email}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="bg-white rounded-8px mb-10px p-16px">
                                    <h4 className="fs-16px fw-500 color-005CF7 mb-10px">Thông tin thanh toán</h4>
                                    <div className="d-flex align-items-center mb-2 justify-content-between">
                                        <div className="fs-14px color-181818">Mã thanh toán</div>
                                        <div className="fs-14px color-181818 fw-500">{code}</div>
                                    </div>
                                    <div className="d-flex align-items-center mb-2 justify-content-between">
                                        <div className="fs-14px color-181818">Tên gói</div>
                                        <div className="fs-14px color-181818 fw-500">{packageData?.name}</div>
                                    </div>
                                    <div className="d-flex align-items-center mb-2 justify-content-between">
                                        <div className="fs-14px color-181818">Người dùng</div>
                                        <div
                                            className="fs-14px color-181818 fw-500">{packageData?.unlimitedUser ? 'Không giới hạn' : number_format(packageData?.limitUser)}</div>
                                    </div>
                                    <div className="d-flex align-items-center mb-2 justify-content-between">
                                        <div className="fs-14px color-181818">Số lượng bản ghi</div>
                                        <div
                                            className="fs-14px color-181818 fw-500">{packageData?.unlimitedClient ? 'Không giới hạn' : number_format(packageData?.limitClient)}</div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="fs-14px color-181818">Thời hạn sử dụng</div>
                                        <div
                                            className="fs-14px color-181818 fw-500">{packagePriceData?.validityPeriod} {convertTypeDate(packagePriceData?.timeUnit)}</div>
                                    </div>
                                    <div className="w-100 h-1px d-block my-4 bg-7C7B7B"/>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="fs-16px color-181818">Tổng tiền</div>
                                        <div
                                            className="fs-16px color-181818 fw-500">{numberUtil.number_format(packagePriceData?.price)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(DetailApprovePackage)