/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {toAbsoluteUrl} from '../../../helpers'
import {AsideMenu} from './AsideMenu'

const AsideDefault: FC = () => {
    const {config, classes} = useLayout()
    const {aside} = config

    return (
        <div id='kt_aside' className={clsx('aside', classes.aside.join(' '))} data-kt-drawer='true'
             data-kt-drawer-name='aside' data-kt-drawer-activate='{default: true, lg: false}'
             data-kt-drawer-overlay='true' data-kt-drawer-width="{default:'200px', '300px': '250px'}"
             data-kt-drawer-direction='start' data-kt-drawer-toggle='#kt_aside_mobile_toggle'>
            <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
                <Link className="d-flex align-items-center" to='/enterprise'>
                    <img alt='Logo' className='h-40px logo' src={toAbsoluteUrl('/assets/logo/logo_iss.svg')}/>
                    <h4 className="text-white mb-0 ms-2" style={{fontWeight: 500}}>ISS365 CMS</h4>
                </Link>
            </div>

            <div className='aside-menu flex-column-fluid'>
                <AsideMenu asideMenuCSSClasses={classes.asideMenu}/>
            </div>

        </div>
    )
}

export {AsideDefault}
