import axios, {HttpStatusCode, Method} from "axios";
import StorageService from "../service/StorageService";
import humps from "humps";
import createAuthRefreshInterceptor from "axios-auth-refresh";

export interface IApiResponse {
    readonly status: number;
    readonly body: any;
    readonly headers?: any;
}

export interface IBodyError {
    readonly errorCode?: number;
    readonly messageCode?: number;
    readonly message: string
}

let apiUrl = process.env.REACT_APP_API_URL
let apiRefreshToken = apiUrl + '/sso/v1/auth/refresh-token'

const refreshAuthLogic = (failedRequest: { config: { headers: any } }) => axios.post(apiRefreshToken, {'refreshToken': StorageService.getRefreshToken()}).then(response => {
    StorageService.setToken(response.data.token);
    failedRequest.config.headers['Authorization'] = 'Bearer ' + response.data.token;
    return Promise.resolve();
}).catch(function (error) {
    return Promise.reject();
});

createAuthRefreshInterceptor(axios, refreshAuthLogic, {
    pauseInstanceWhileRefreshing: true
});

axios.interceptors.request.use((config: any) => {
    if(StorageService.getToken()){
        config.headers['Authorization'] = 'Bearer ' + StorageService.getToken();
    }
    return config;
});

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (originalRequest.url === apiRefreshToken) {
            toastr.error('Phiên đăng nhập hết hạn');
            setTimeout(() => {
                localStorage.clear()
                StorageService.removeToken();
                StorageService.removeRefreshToken();
                window.location.href = '/auth'
            }, 600)
        }
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                await createAuthRefreshInterceptor(axios, refreshAuthLogic);
                return axios(originalRequest);
            } catch (refreshError) {
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);


const responseError = (error: any, methodGet?: boolean) => {
    let bodyError: IBodyError;

    try {
        if (error && error?.status === HttpStatusCode.Forbidden) {
            bodyError = {
                errorCode: HttpStatusCode.Forbidden,
                message: "Không có quyền truy cập!"
            }
        } else if (error && error.status === HttpStatusCode.InternalServerError) {
            bodyError = {
                errorCode: HttpStatusCode.InternalServerError,
                message: "Lỗi máy chủ nội bộ, vui lòng thử lại sau!"
            }
        } else {
            bodyError = {
                errorCode: error?.data?.errorCode ?? error?.status,
                messageCode: error?.data?.messageCode,
                message: error?.data?.message
            }
        }

        if(methodGet){
            toastr.error(bodyError.message)
        }
    } catch (e) {
        if(methodGet){
            toastr.error('Đã có lỗi xảy ra!')
        }
        bodyError = {
            errorCode: HttpStatusCode.NotExtended,
            message: "Đã có lỗi xảy ra!"
        }
    }

    const apiResponse: IApiResponse = {
        status: error?.status,
        body: bodyError
    };

    return apiResponse;
}

export async function getRequest(path: string): Promise<IApiResponse> {
    let newHeaders: any = {'Content-Type': 'application/json'}

    if (StorageService.isTokenExits()) {
        newHeaders = {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            Authorization: 'Bearer ' + StorageService.getToken()
        }
    }


    return await axios.get(apiUrl + path, {headers: newHeaders})
        .then(
            (response) => {
                const apiResponse: IApiResponse = {
                    status: response.status,
                    body:  humps.camelizeKeys(response.data),
                    headers: response.headers
                };
                return apiResponse;
            },
            (error) => {
                return responseError(error?.response, true)
            }
        )
}

export async function postRequest(path: string, params: object): Promise<IApiResponse> {
    return apiCall(path, "POST", params);
}

export async function patchRequest(path: string, params: object): Promise<IApiResponse> {
    return apiCall(path, "PATCH", params);
}

export function apiCall(path: string, _method: Method = "POST", _params: object): Promise<IApiResponse> {
    let newHeaders: any = {'Content-Type': 'application/json'}

    if (StorageService.isTokenExits()) {
        newHeaders = {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            Authorization: 'Bearer ' + StorageService.getToken()
        }
    }

    return new Promise<IApiResponse>((resolve) => {
        axios({
            data: JSON.stringify(_params),
            headers: newHeaders,
            method: _method,
            url: apiUrl + path
        })
            .then(function (response) {
                resolve({
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                });
            })
            .catch(function (error) {
                resolve(responseError(error?.response))
            });

    });
}

export async function putRequest(path: string, params: object): Promise<IApiResponse> {
    return apiCall(path, "PUT", params);
}

export async function deleteRequest(path: string, params: object): Promise<IApiResponse> {

    return apiCall(path, "DELETE", params);
}
