import React from 'react';
import {observer} from "mobx-react";
import {enterpriseStore} from "../EnterpriseStore";


const PopupApprove = () => {

    let {id} = enterpriseStore.dataRequest

    return (
        <div className="modal fade" id="popup_approve" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document" style={{maxWidth: 650}}>
                <div className="modal-content border-0">
                    <div className="text-center pt-6 mb-4">
                        <img width={64} height={65} src="/assets/ico/ico_confirm.svg" alt=""/>
                        <h5 className="modal-title mt-2">Xác nhận duyệt</h5>
                    </div>
                    <div className="modal-body pt-2">
                        <div className="action_footer d-flex justify-content-between">
                            <button className="bt_cancel" data-bs-dismiss="modal">Hủy
                            </button>
                            <button className="send-request bt_approve" onClick={() => enterpriseStore.changeState(id, "APPROVED")}
                                    disabled={enterpriseStore.isLoadingBt}>Xác nhận
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(PopupApprove);