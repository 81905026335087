import React from 'react';
import {observer} from "mobx-react";
import {enterpriseStore} from "../EnterpriseStore";
import {numberUtil} from "../../../common/utils/NumberUtil";
import {Drawer} from 'antd';
import {urlImage} from "../../../common/utils/Utils";
import "../EnterpriseStyle.scss"


const DetailEnterprise = () => {


    let {name, logo, ownerPhone, ownerName, businessNumber, numBranch, address, directive} = enterpriseStore.dataRequest


    return (
        <Drawer className="detail_enterprise" title="CHI TIẾT DOANH NGHIỆP" width={550} onClose={() => enterpriseStore.isOpenDetail = false} open={enterpriseStore.isOpenDetail}>
            <>
                <div className="enterprise_info d-flex mb-4">
                    <div className="me-3">
                        <img src={urlImage(logo)} alt=""/>
                    </div>
                    <span className="">{name}</span>
                </div>
                <div className="d-flex info_ align-items-center justify-content-between">
                    <div className="label">Chủ sở hữu:</div>
                    <span className="value">{ownerName}</span>
                </div>
                <div className="d-flex info_ align-items-center justify-content-between">
                    <div className="label">Số điện thoại:</div>
                    <span className="value">{ownerPhone}</span>
                </div>
                <div className="d-flex info_ align-items-center justify-content-between">
                    <div className="label">Quy mô:</div>
                    <span className="value">{numberUtil.formatNumber(businessNumber)}</span>
                </div>
                <div className="d-flex info_ align-items-center justify-content-between">
                    <div className="label">Số chi nhánh:</div>
                    <span className="value">{numberUtil.formatNumber(numBranch)}</span>
                </div>
                <div className="d-flex info_ align-items-center justify-content-between">
                    <div className="label">Thông tư:</div>
                    <span className="value">{directive}</span>
                </div>
                <div className="d-flex info_ align-items-center">
                    <div className="label me-2">Địa chỉ:</div>
                    <span className="value">{address?.detail} {address?.ward?.name} {address?.district?.name} {address?.province?.name}</span>
                </div>
            </>
        </Drawer>

    );
}

export default observer(DetailEnterprise);