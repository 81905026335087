import {makeAutoObservable} from 'mobx'
import $ from 'jquery'
import {HttpStatusCode} from "axios";
import {approvedPackageService} from "./ApprovedPackageService";

class ApprovedPackageStore {
    id: number | string = ''
    page: number = 0
    status: number | null = null
    keyword: string = ''
    totalPage: number = 0
    isLoading: boolean = false
    isLoadingBt: boolean = false
    listApprovedPackage: any[] = []
    dataDetail: any = {
        packageData: {
            name: '',
            limitUser: '',
            limitClient: '',
            unlimitedUser: false,
            unlimitedClient: false,
        },
        bill: {
            amount: ''
        },
        packagePriceData: [],
        enterprise: {
            name: "",
            ownerName: "",
            ownerPhone: "",
            businessNumber: "",
            numBranch: "",
        },
        user: {
            name: "",
            avatar: "",
        },
        status: "",
        customer: {
            name: "",
            email: "",
            phone: ""
        },
        price: '',
        module: "",
        validityPeriod: 1,
        timeUnit: "",
    }
    listStatus: { id: string, name: string }[] = [
        {id: 'PENDING', name: 'Chờ duyệt'},
        {id: 'APPROVED', name: 'Đã duyệt'},
        {id: 'REJECTED', name: 'Từ chối'},
        {id: 'CANCELED', name: 'Hủy'},
    ]

    constructor() {
        makeAutoObservable(this)
    }

    async getApprovedPackage() {
        this.isLoading = true;
        const result = await approvedPackageService.getApprovedPackage()
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            this.listApprovedPackage = result.body.data;
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: any) {
        const result = await approvedPackageService.getDetail(id);
        if (result.status === HttpStatusCode.Ok) {
            this.dataDetail = result.body
        }
    }

    convertTitle(status: string) {
        switch (status) {
            case "APPROVED":
                return "Duyệt"
            case "REJECTED":
                return "Từ chối duyệt"
            case "CANCELED":
                return "Hủy duyệt"
        }
    }

    async changeState(id: number | string, status: string) {

        this.isLoadingBt = true
        const result = await approvedPackageService.changeStatus(id, status)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.Ok) {
            toastr.success(`${this.convertTitle(status)} yêu cầu nâng cấp gói thành công`)
            this.listApprovedPackage.map((item: any) => {
                if (item.id == id) {
                    item.status = status
                    item.approveAt = new Date()
                }
            })
            $('.action_footer .bt_cancel').trigger('click')
        } else toastr.error(result.body.message)

    }
}

export const approvedPackageStore = new ApprovedPackageStore();


