import {getRequest, IApiResponse, putRequest} from '../../common/helpers/RequestHelper'
import {approvedPackageStore} from "./ApprovedPackageStore";


class ApprovedPackageService {

  public getApprovedPackage(): Promise<IApiResponse>{
    return getRequest(`/iss_365_user/package_transaction?page=${approvedPackageStore.page}&size=10${approvedPackageStore.keyword?.trim() ? `&keyword=${approvedPackageStore.keyword?.trim()}` : ''}${approvedPackageStore.status ? `&status=${approvedPackageStore.status}` : ''}`)
  }

  public getDetail(id: number | string): Promise<IApiResponse>{
    return getRequest(`/iss_365_user/package_transaction/${id}`)
  }

  public changeStatus(id: number | string, status: string): Promise<IApiResponse>{
    return putRequest(`/iss_365_user/package_transaction/${id}?status=${status}`, {})
  }

}

export const approvedPackageService = new ApprovedPackageService()

