import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import Loading from '../../common/components/Loading'
import NoContent from '../../common/components/NoContent'
import Paginate from '../../common/components/Paginate'
import {sttPage, urlImage} from '../../common/utils/Utils'
import {dateUtils} from "../../common/utils/DateUtils";
import {enterpriseStore} from "./EnterpriseStore";
import {Tag} from "antd";
import ActionTable from "../../common/components/actionTable/ActionTable";
import PopupApprove from "./components/PopupApprove";
import PopupReject from "./components/PopupReject";
import SelectAnt from "../../common/components/form/SelectAnt";
import "./EnterpriseStyle.scss"
import DetailEnterprise from "./components/DetailEnterprise";
import InputSearch from "../../common/components/form/InputSearch";


export const stateApproveEnterprise = (state: string) => {
    switch (state) {
        case "APPROVED":
            return <Tag bordered={false} className="fs-14 text-center pe-2 ps-2" color="#efffef"
                        style={{width: 82, color: '#158212', borderRadius: 12}}>Đã duyệt</Tag>
        case "REJECT":
            return <Tag bordered={false} className="fs-14 text-center pe-2 ps-2" color="#FFEEEF"
                        style={{width: 82, color: '#E3000F', borderRadius: 12}}>Từ chối</Tag>
        case "PENDING":
            return <Tag bordered={false} className="fs-14 text-center pe-2 ps-2" color="#E6E6E6"
                        style={{width: 82, color: '#808089', borderRadius: 12}}>Chờ duyệt</Tag>
    }
}

const EnterprisePage = () => {

    const handlePageClick = async (data: any) => {
        enterpriseStore.page = data.selected
        await enterpriseStore.getEnterprise()
    }

    useEffect(() => {
        enterpriseStore.getEnterprise().then()
    }, [])

    const callApi = () => {
        enterpriseStore.page = 0
        enterpriseStore.getEnterprise().then()
    }

    return (
        <div className="enterprise_page">
            <div className='d-flex align-items-center justify-content-between mb-6'>
                <h1 className='d-flex color-005CF7 fw-bolder fs-3 align-items-center my-1'>Doanh nghiệp</h1>
            </div>

            <div className="d-flex align-items-center mb-6">
                <InputSearch value={enterpriseStore.keyword} callApi={() => callApi()} onChange={(e: any) => enterpriseStore.keyword = e.currentTarget.value}/>
                <SelectAnt width={220} isShowClear value={enterpriseStore.state} onChange={(e: any) => {
                    enterpriseStore.state = e
                    enterpriseStore.getEnterprise().then()
                }} placeholder="Trạng thái" options={enterpriseStore.listState}/>
            </div>

            <div id='kt_ecommerce_sales_table_wrapper' className='dataTables_wrapper dt-bootstrap4 no-footer'>
                {enterpriseStore.isLoading ? <Loading/> : <div className='table-responsive'>
                    {enterpriseStore.listEnterprise && enterpriseStore.listEnterprise.length > 0 ?
                        <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th>STT</th>
                                <th>Doanh nghiệp</th>
                                <th>Chủ đơn vị</th>
                                <th>Số điện thoại</th>
                                <th>Trạng thái</th>
                                <th>Thời gian cập nhật</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {enterpriseStore.listEnterprise.map((item: any, i: number) => {
                                return (
                                    <tr key={i}>
                                        <td>{sttPage(enterpriseStore.page, i)}</td>
                                        <td className="cursor-pointer info_enterprise" onClick={() => {
                                            enterpriseStore.isOpenDetail = true
                                            enterpriseStore.dataRequest = item
                                        }}>
                                            <div className="d-flex align-items-center">
                                                <div className="symbol symbol-50px me-3">
                                                    <img className="rounded-1 object-fit-cover" src={urlImage(item.logo)} alt=""/>
                                                </div>
                                                <span>{item.name}</span>
                                            </div>
                                        </td>
                                        <td>{item.ownerName}</td>
                                        <td>{item.ownerPhone}</td>
                                        <td>{stateApproveEnterprise(item.state)}</td>
                                        <td>{dateUtils.formatDate(item.updatedAt)}</td>
                                        <td width='10%'>
                                            <ActionTable
                                                data={[
                                                    {
                                                        name: "Chi tiết",
                                                        type: "detail",
                                                        action: () => {
                                                            enterpriseStore.isOpenDetail = true
                                                            enterpriseStore.dataRequest = item
                                                        },
                                                    },
                                                    {
                                                        name: "Duyệt",
                                                        type: "success",
                                                        isHide: item.state !== "PENDING",
                                                        dataTarget: 'popup_approve',
                                                        action: () => enterpriseStore.getDetail(item.id),
                                                    },
                                                    {
                                                        name: "Từ chối",
                                                        type: "cancel",
                                                        isHide: item.state !== "PENDING",
                                                        dataTarget: 'popup_reject',
                                                        action: () => enterpriseStore.getDetail(item.id),
                                                    }
                                                ]}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                    <Paginate callback={handlePageClick} totalPage={enterpriseStore.totalPage}
                              currentPage={enterpriseStore.page}/>
                </div>}
            </div>
            <DetailEnterprise/>
            <PopupApprove/>
            <PopupReject/>
        </div>
    )
}

export default observer(EnterprisePage)