import React from "react";
import {Link} from "react-router-dom";
import "./ActionTable.scss";

interface IProps {
    id?: any;
    title?: string;
    isHide?: boolean,
    data: {
        name: string;
        type?:
            "add" | "history"
            | "permission"
            | "edit"
            | "note"
            | "download"
            | "detail"
            | "delete"
            | "print"
            | "success"
            | "cancel"
            | "lock"
            | "open"
            | "update"
            | "change"
            | "verify"
            | "unverify"
            | "allocation"
            | "transport"
            | "stop" | "reimbursement" | "transmit";
        url?: string;
        icon?: string;
        isHide?: boolean;
        loading?: boolean;
        dataTarget?: string;
        action?: any;
    }[];
}

const ActionTable: React.FC<IProps> = ({id, isHide, title, data}: IProps) => {
    const returnIcon = (type: string) => {
        switch (type) {
            case "transmit":
                return "transmit.svg";
            case "reimbursement":
                return "reimbursement.svg";
            case "add":
                return "plus-square-grey.svg";
            case "history":
                return "file-clock.svg";
            case "edit":
                return "PenEdit.svg";
            case "permission":
                return "permission.svg";
            case "note":
                return "ico_note.svg";
            case "download":
                return "ico_download.svg";
            case "detail":
                return "ClipBoardList.svg";
            case "success":
                return "IconCheck.svg";
            case "verify":
                return "jobVerify.svg";
            case "unverify":
                return "jobUnverify.svg";
            case "cancel":
                return "cancel.svg";
            case "allocation":
                return "allocation.svg";
            case "change":
                return "arrow-up-left-square.svg";
            case "delete":
                return "trash.svg";
            case "print":
                return "printer.svg";
            case "transport":
                return "truck.svg";
            case "stop":
                return "pause.svg";
        }
    };

    if (!isHide) {
        return (
            <div className="d-flex justify-content-center">
                <div style={data?.filter((i: any) => !(i.isHide)).length <= 0 ? {display: 'none'} : {}}
                     className="actionWrapper dropdown">
                    <div className="titleWrapper" id={`actionTable${id ?? ""}`} data-bs-toggle="dropdown">
                        <p>{title ?? "Thao tác"}</p>
                        <img
                            src="/assets/ico/action/ChevronDropIcon.svg"
                            className="icon-action"
                            alt=""
                            title="Thao tác"
                        />
                    </div>
                    <div
                        className="dropdownWrapper dropdown-menu"
                        aria-labelledby={`actionTable${id ?? ""}`}
                    >
                        <div className="topWrapper">
                            {data &&
                            data.map((item: any, index: number) => {
                                if (!item.isHide) {
                                    if (item.url) {
                                        return (
                                            <Link
                                                to={item.url}
                                                onClick={() => item.action && item.action()}
                                                className="items"
                                                key={index}
                                            >
                                                <img
                                                    src={
                                                        item.icon
                                                            ? item.icon
                                                            : `/assets/ico/action/${returnIcon(item.type)}`
                                                    }
                                                    style={{width: "20px"}}
                                                    className="icon-action"
                                                    alt=""
                                                    title={item.name}
                                                />
                                                <p>{item.name}</p>
                                            </Link>
                                        );
                                    } else {
                                        if (item.dataTarget) {
                                            return (
                                                <button
                                                    onClick={() => item.action && item.action()}
                                                    data-bs-toggle="modal"
                                                    data-bs-target={`#${item.dataTarget}`}
                                                    className={`items ${index > 0 ? "item_border" : ""} ${
                                                        item.type
                                                    }`}
                                                    key={index}
                                                >
                                                    <img
                                                        src={
                                                            item.icon
                                                                ? item.icon
                                                                : `/assets/ico/action/${returnIcon(item.type)}`
                                                        }
                                                        style={{width: "20px"}}
                                                        className="icon-action"
                                                        alt=""
                                                        title={item.name}
                                                    />
                                                    <p className='text-start'>{item.name}</p>
                                                </button>
                                            );
                                        } else {
                                            return (
                                                <button
                                                    disabled={item.loading}
                                                    onClick={() => item.action && item.action()}
                                                    className={`items ${index > 0 ? "item_border" : ""} ${
                                                        item.type
                                                    }`}
                                                    key={index}
                                                >
                                                    <img
                                                        src={
                                                            item.icon
                                                                ? item.icon
                                                                : `/assets/ico/action/${returnIcon(item.type)}`
                                                        }
                                                        className="icon-action"
                                                        alt=""
                                                        title={item.name}
                                                    />
                                                    <p>{item.name}</p>
                                                </button>
                                            );
                                        }
                                    }
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    } else return null
};

export default ActionTable;
