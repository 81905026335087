import React from 'react';
import {observer} from "mobx-react";
import {enterpriseStore} from "../EnterpriseStore";

const PopupReject = () => {

    let {id} = enterpriseStore.dataRequest

    return (
        <div className="modal fade" id="popup_reject" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document" style={{maxWidth: 650}}>
                <div className="modal-content border-0">
                    <div className="text-center pt-6 mb-4">
                        <img width={64} height={65} src="/assets/ico/ico_reject.svg" alt=""/>
                        <h5 className="modal-title mt-2">Từ chối duyệt</h5>
                    </div>
                    <div className="modal-body pt-2">
                        <div className="action_footer d-flex justify-content-between">
                            <button className="bt_cancel" data-bs-dismiss="modal">Hủy</button>
                            <button className="send-request bt_reject"
                                    onClick={() => enterpriseStore.changeState(id, 'REJECT')}
                                    disabled={enterpriseStore.isLoadingBt}>Xác nhận
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(PopupReject);