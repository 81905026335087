import React from 'react'
import {observer} from 'mobx-react-lite'
import {packageStore, TypeTime} from "../PackageStore";
import InputAnt from "../../../common/components/form/InputAnt";
import {numberUtil} from "../../../common/utils/NumberUtil";
import {Checkbox, Radio, Switch} from 'antd';
import NoContentTable from "../../../common/components/NoContentTable";
import {number_format} from "../../../common/utils/Utils";

const AddEditPackage = () => {

    const addPackagePrice = () => {
        packageStore.dataRequest.packagePrices.push({
            price: '',
            validityPeriod: '',
            errorPrice: false,
            errorValidityPeriod: false,
        })
    }

    let {limitClient, limitUser} = packageStore.dataRequest

    return (
        <div className='modal fade' id='add_edit_package' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered mw-1000px'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h2 className="color-005CF7">Thiết lập gói {packageStore.dataRequest.type === TypeTime.PAID ? 'bán' : 'dùng thử'}</h2>
                        <button type="button" id="close_package" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"/>
                    </div>
                    <div className='modal-body py-lg-10 px-lg-10'>
                        {!packageStore.dataRequest.id && <div className="d-flex gap-5 mb-5">
                            <div className="w-50">
                                <Radio checked={packageStore.dataRequest.type === TypeTime.PAID}
                                       onChange={() => {
                                           packageStore.dataRequest.type = TypeTime.PAID
                                           packageStore.dataRequest.packagePrices = [
                                               {
                                                   price: '',
                                                   validityPeriod: '',
                                                   errorPrice: false,
                                                   errorValidityPeriod: false,
                                               }
                                           ]
                                       }}>
                                    Gói trả phí
                                </Radio>
                            </div>
                            <div className="w-50">
                                <Radio checked={packageStore.dataRequest.type === TypeTime.TRIAL}
                                       onChange={() => {
                                           packageStore.dataRequest.type = TypeTime.TRIAL
                                           packageStore.dataRequest.packagePrices = [
                                               {
                                                   validityPeriod: 1,
                                                   errorValidityPeriod: false,
                                               }
                                           ]
                                       }}>
                                    Gói dùng thử
                                </Radio>
                            </div>
                        </div>}
                        <div className="w-full mb-5">
                            <label className='form-label mb-2' style={{lineHeight: `22px`}}>Tên gói <span
                                className="text-danger">*</span></label>
                            <InputAnt value={packageStore.dataRequest.name} showCount
                                      isError={packageStore.errors.name}
                                      onChange={(e: any) => packageStore.dataRequest.name = e.currentTarget.value}
                                      placeholder='Nhập tên gói'/>

                        </div>
                        <div className="d-flex gap-5 mb-5">
                            <div className='w-50'>
                                <div className="d-flex mb-2 align-items-center justify-content-between">
                                    <label className='form-label mb-0'>Số lượng người dùng <span
                                        className="text-danger">*</span></label>
                                    <div className="d-flex align-items-center gap-2">
                                    Không giới hạn người dùng
                                        <Checkbox checked={packageStore.dataRequest.unlimitedUser}
                                                  onChange={(e: any) => packageStore.dataRequest.unlimitedUser = e.target.checked}/>
                                    </div>
                                </div>
                                <InputAnt
                                    value={packageStore.dataRequest.unlimitedUser ? 'Không giới hạn' : limitUser ? number_format(limitUser ) : ''}
                                    disabled={packageStore.dataRequest.unlimitedUser}
                                    isError={packageStore.errors.limitUser} maxLength={10}
                                    onChange={(e: any) => packageStore.dataRequest.limitUser = numberUtil.regexNumber(e)}
                                    placeholder='Nhập số lượng người dùng'/>
                            </div>
                            <div className='w-50'>
                                <div className="d-flex mb-2 align-items-center justify-content-between">
                                    <label className='form-label mb-0'>Số lượng dữ liệu <span
                                        className="text-danger">*</span></label>
                                    <div className="d-flex align-items-center gap-2">
                                        Không giới hạn bản ghi
                                        <Checkbox checked={packageStore.dataRequest.unlimitedClient}
                                                  onChange={(e: any) => packageStore.dataRequest.unlimitedClient = e.target.checked}/>
                                    </div>
                                </div>
                                <InputAnt
                                    value={packageStore.dataRequest.unlimitedClient ? 'Không giới hạn' : limitClient ? number_format(limitClient ) : ''}
                                    disabled={packageStore.dataRequest.unlimitedClient}
                                    isError={packageStore.errors.limitClient} maxLength={14}
                                    onChange={(e: any) => packageStore.dataRequest.limitClient = numberUtil.regexNumber(e)}
                                    placeholder='Nhập số lượng dữ liệu'/>
                            </div>
                        </div>
                        {packageStore.dataRequest.type === TypeTime.PAID ? <>
                            <div className="d-flex justify-content-between gap-5 mb-5">
                                <div className="d-flex w-50 align-items-center gap-2">
                                    Gói khuyên dùng
                                    <Checkbox checked={packageStore.dataRequest.isRecommended}
                                              onChange={(e: any) => packageStore.dataRequest.isRecommended = e.target.checked}/>
                                </div>

                                <div className="d-flex w-50 align-items-center gap-2">
                                    Trạng thái
                                    <Switch checked={packageStore.dataRequest.isActive}
                                            onChange={() => packageStore.dataRequest.isActive = !packageStore.dataRequest.isActive}/>
                                </div>
                            </div>
                            <div className="d-flex mb-4 align-items-center justify-content-between">
                                <h4 className="fs-16px fw-500 color-005CF7">Giá gói</h4>
                                <button className='btn btn-sm fs-14px color-005CF7 bg-ECF5FF'
                                        onClick={() => addPackagePrice()}>+ Thêm
                                </button>
                            </div>
                            <div className="table-responsive p-0 border">
                                <table className="table align-middle table-hover gy-7 gs-7 mb-0">
                                    <thead>
                                    <tr className="bg-F2F3F5">
                                        <th className="text-center">STT</th>
                                        <th className="text-center">Năm</th>
                                        <th className="text-end">Giá bán</th>
                                        <th className="text-center"/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {packageStore.dataRequest.packagePrices && packageStore.dataRequest.packagePrices.length > 0 ? (
                                        packageStore.dataRequest.packagePrices.map((item: any, i: any) => {
                                                return (
                                                    <tr key={i}>
                                                        <td width="10%" className="text-center">{i+1}</td>
                                                        <td width="15%">
                                                            <InputAnt noTextErr={true} className="text-center" isError={item.errorValidityPeriod}
                                                                      value={item.validityPeriod} disabled={i === 0} maxLength={2}
                                                                      onChange={(e: any) => item.validityPeriod = numberUtil.regexNumber(e)}
                                                                      placeholder='Nhập năm'/>
                                                        </td>
                                                        <td>
                                                            <InputAnt noTextErr={true} className="text-end" maxLength={14}
                                                                      value={item.price ? number_format(item.price) : ''} isError={item.errorPrice}
                                                                      onChange={(e: any) => item.price = numberUtil.regexNumber(e)}
                                                                      placeholder='Nhập giá bán'/>
                                                        </td>
                                                        <td width="10%" className="text-center ps-0">
                                                            {packageStore.dataRequest.packagePrices && packageStore.dataRequest.packagePrices.length > 1 && i > 0 &&
                                                            <img src={`/assets/ico/action/trash.svg`} className="cursor_pointer"
                                                                 onClick={() => packageStore.dataRequest.packagePrices.splice(i, 1)} alt=""/>}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    ) : (
                                        <NoContentTable height={120} colSpan={4}/>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </> :  <div className='form-group'>
                            <label className='form-label light-height-22px mb-2'>Thời hạn dùng thử <span
                                className="text-danger">*</span></label>
                            <InputAnt value={packageStore.dataRequest.packagePrices[0].validityPeriod} suffix="Ngày"
                                      isError={packageStore.dataRequest.packagePrices[0].errorValidityPeriod} maxLength={3}
                                      onChange={(e: any) => packageStore.dataRequest.packagePrices[0].validityPeriod = numberUtil.regexNumber(e)}
                                      placeholder='Nhập thời hạn dùng thử'/>
                        </div>}
                    </div>

                    <div className='modal-footer d-flex justify-content-end py-6 px-9'>
                        <button type='reset' className='btn btn-light btn-active-light-primary me-2'
                                data-bs-dismiss='modal'>Hủy
                        </button>
                        <button type='submit' className='btn btn-primary' onClick={() => packageStore.addEdit()}
                                disabled={packageStore.isLoadingBt}>
                            {packageStore.isLoadingBt ? (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                    Vui lòng chờ...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                                 </span>
                            ) : 'Lưu'}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default observer(AddEditPackage)