import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import Loading from '../../common/components/Loading'
import NoContent from '../../common/components/NoContent'
import Paginate from '../../common/components/Paginate'
import {number_format, sttPage} from '../../common/utils/Utils'
import {Radio, Switch} from "antd";
import ActionTable from "../../common/components/actionTable/ActionTable";
import InputSearch from "../../common/components/form/InputSearch";
import {packageStore, TypeTime} from "./PackageStore";
import PopupConfirm from "../../common/components/PopupConfirm";
import AddEditPackage from "./components/AddEditPackage";
import {numberUtil} from "../../common/utils/NumberUtil";



const PackagePage = () => {

    const handlePageClick = async (data: any) => {
        packageStore.page = data.selected
        await packageStore.getPackage()
    }

    useEffect(() => {
        packageStore.getPackage().then()
    }, [])

    const callApi = () => {
        packageStore.page = 0
        packageStore.getPackage().then()
    }

    return (
        <div className="enterprise_page">
            <div className='d-flex align-items-center justify-content-between mb-6'>
                <h1 className='d-flex color-005CF7 fw-bolder fs-3 align-items-center my-1'>Thiết lập gói</h1>
                <button className='btn btn-sm btn-primary' onClick={() => packageStore.clearForm()} data-bs-toggle='modal' data-bs-target='#add_edit_package'>+ Tạo mới</button>

            </div>
            <div className="d-flex mb-4 align-items-center">
                <InputSearch value={packageStore.name} callApi={() => callApi()} onChange={(e: any) => packageStore.name = e.currentTarget.value}/>
            </div>
            <div id='kt_ecommerce_sales_table_wrapper' className='dataTables_wrapper dt-bootstrap4 no-footer'>
                {packageStore.isLoading ? <Loading/> : <div className='table-responsive'>
                    {packageStore.listPackage && packageStore.listPackage.length > 0 ?
                        <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th>STT</th>
                                <th>Tên gói</th>
                                <th>Giá gói (1 năm)</th>
                                <th className="text-center">Số lượng <br/> người dùng</th>
                                <th className="text-center">Số lượng <br/> bản ghi</th>
                                <th className="text-center">Gói <br/> khuyên dùng</th>
                                <th className="text-center">Trạng thái</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {packageStore.listPackage.map((item: any, i: number) => {
                                return (
                                    <tr key={i}>
                                        <td>{sttPage(packageStore.page, i)}</td>
                                        <td>{item.name}</td>
                                        <td>{item.type === TypeTime.TRIAL ? "Miễn phí" : item.packagePrices && item.packagePrices.length > 0 ? numberUtil.number_format(item.packagePrices[0]?.price) : ''}</td>
                                        <td className="text-center">{item.unlimitedUser ? 'Không giới hạn' : number_format(item.limitUser)}</td>
                                        <td className="text-center">{item.unlimitedClient ? 'Không giới hạn' : number_format(item.limitClient)}</td>
                                        <td className="text-center"><Radio checked={item.isRecommended} disabled={item.type === TypeTime.TRIAL} onChange={() => {
                                            if(!item.isRecommended){
                                                packageStore.changeRecommended(item.id).then()
                                            }
                                        }}/></td>
                                        <td className="text-center">
                                            <Switch title={!item.isActive ? "Bật" : "Ngưng"}
                                                    checked={item.isActive}
                                                    onChange={() => packageStore.changeState(item.id, item.isActive)}
                                            />
                                        </td>
                                        <td width='10%'>
                                            <ActionTable
                                                data={[
                                                    {
                                                        name: "Chỉnh sửa",
                                                        type: "edit",
                                                        dataTarget: 'add_edit_package',
                                                        action: () => packageStore.getDetail(item.id),
                                                    },
                                                    {
                                                        name: "Xóa",
                                                        type: "delete",
                                                        dataTarget: 'popup_confirm',
                                                        action: () => packageStore.id = item.id,
                                                    }
                                                ]}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                    <Paginate callback={handlePageClick} totalPage={packageStore.totalPage}
                              currentPage={packageStore.page}/>
                    <AddEditPackage/>
                    <PopupConfirm isLoading={packageStore.isLoading} action={() => packageStore.delete()}/>
                </div>}
            </div>
        </div>
    )
}

export default observer(PackagePage)