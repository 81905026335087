/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import {login} from "../core/_requests";
import StorageService from '../../../common/service/StorageService'
import {observer} from "mobx-react";
import {profileStore} from '../../profile/ProfileStore'


const loginSchema = Yup.object().shape({
    username: Yup.string()
        .required('Tài khoản không được để trống'),
    password: Yup.string()
        .required('Mật khẩu không được để trống'),
})

const initialValues = {
    username: '',
    password: '',
}

const Login = observer(() => {
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            const result = await login(values.username, values.password, 'iss')
            if (result.status === 200) {
                if (result.body.claims.isAdmin) {
                    saveAuth(result.body)
                    StorageService.setToken(result.body.token);
                    StorageService.setRefreshToken(result.body.refreshToken);
                    await profileStore.getProfile(setStatus, saveAuth)
                    setCurrentUser(profileStore.profileDetail)
                } else {
                    setStatus('Tài khoản không phải admin!')
                }
                setLoading(false)
            } else {
                setStatus(result.body.message ?? 'Đã có lỗi xảy ra!')
                saveAuth(undefined)
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>Đăng nhập</h1>
            </div>

            {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            )}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Tài khoản</label>
                <input
                    placeholder='Nhập tài khoản'
                    {...formik.getFieldProps('username')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {'is-invalid': formik.touched.username && formik.errors.username},
                        {
                            'is-valid': formik.touched.username && !formik.errors.username,
                        }
                    )}
                    type='email'
                    autoComplete='off'
                />
                {formik.touched.username && formik.errors.username && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.username}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className='fv-row mb-10'>
                <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mật khẩu</label>
                    </div>
                </div>
                <input
                    type='password'
                    placeholder='Nhập mật khẩu'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                            'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Đăng nhập</span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'/>
            </span>
                    )}
                </button>
            </div>
        </form>
    )
})


export default Login