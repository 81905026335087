import {Input} from "antd";
import React, {useCallback} from "react";


interface IProps {
    value: any;
    defaultValue?: any;
    onChange?: any;
    isBorderError?: any;
    isError?: any;
    type?: any;
    disabled?: any;
    noTextErr?: boolean;
    placeholder?: string;
    className?: string;
    handleBlur?: any
    onKeyPress?: any,
    maxLength?: number,
    showCount?: boolean,
    onKeyDown?: () => void,
    suffix?: string,
    loadingAction?: boolean
    style?: any
    readOnly?: boolean
    notValue?: boolean
}

const InputAnt = (item: IProps) => {

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = e.target.value.replace(/^\s+/, '');
        const continuousSequences = e.target.value?.split(/\s+/)?.filter(str => str?.length > 0);
        const hasTooLongSequence = continuousSequences?.some(sequence => sequence?.length > 30);
        if (hasTooLongSequence) {
            toastr.warning('Từ đơn không được quá 30 ký tự liên tiếp!')
            return
        }
        if (item.onChange) item.onChange(e);

    }, [item.onChange]);


    let _value = item?.notValue ? item?.defaultValue : item?.value
    let isValid = item.isError && !_value && !(typeof _value === "string" && String(_value)?.trim())

    return (
        <>
            <div className="input-group">
                <Input
                    type={item.type ?? "text"} showCount={item.showCount}
                    value={item?.notValue ? undefined :  item.value ?? ""} maxLength={item.maxLength ?? 200}
                    defaultValue={item.defaultValue ?? ""}
                    onChange={(e: any) => !item?.notValue &&  handleChange(e)}
                    disabled={item.disabled}
                    className={`${(isValid || item.isBorderError) ? "border_error" : ""} ${item.className}`}
                    style={{...item.style}}
                    onKeyDown={(e: any) => {
                        if (e.key === "Enter" && !item.loadingAction) {
                            item.onKeyDown && item.onKeyDown()
                        }
                    }}
                    onKeyPress={item.onKeyPress}
                    placeholder={item.placeholder ?? ""}
                    onBlur={(e) => item?.handleBlur && item?.handleBlur(e)}
                    readOnly={item?.readOnly}
                    suffix={item?.suffix || ""}
                />
            </div>
            {!item.noTextErr && isValid && (
                <p className="error">{item.isError}</p>
            )}
        </>
    );
};

export default InputAnt;
