
export function sttPage(page: number, index: any) {
    let start = 1
    if (page > 0) {
        start = page * 10 + index + 1
    } else {
        start = start + index
    }
    return start
}


export function getPathName(index?: any) {
    let path: any = window.location.pathname.split('/')[index ?? 1]
    return path;
}

export function setCookie(name: string, value: any, days: number) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name: string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function getUrlParameter(name: any) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ''));
}


export function getIDParameter(index?: any) {
    let ids = window.location.pathname.split('/')[index ?? 3]
    return ids;
}

export function urlImage(url: any) {
    let image: any = url
    let originalDomain = "https://ommanilife-prod.s3.ap-southeast-1.amazonaws.com"
    let originalDomainDev = "https://ommanilife-dev.s3.ap-southeast-1.amazonaws.com"
    let newDomain = process.env.REACT_APP_API_VIEWIMG
    if(image && (image.startsWith(originalDomain) || image.startsWith(originalDomainDev))){
        image = image.replace(originalDomain, newDomain).replace(originalDomainDev, newDomain)
    }else {
        image = newDomain + url
    }

    return image
}


export function checkWebsite(link: string){
    try {
        const url = new URL(link);
        return !!url.protocol && !!url.host
    } catch (error) {
        return false
    }
}

export function checkLength(arr: any[], length?: number) {
    let num = length ?? 0
    let check = false
    if (arr && arr.length > num) {
        check = true
    }
    return check
}



export function eraseCookie(name: string) {
    setCookie(name, "", -1);
}

export function number_format(number: any, decimals?: any, dec_point?: any, thousands_sep?: any) {
    if(number){
        var n = number, c = isNaN(decimals = Math.abs(decimals)) ? 2 : decimals;

        var t = thousands_sep == undefined ? "." : thousands_sep,
          s = n < 0 ? "-" : "";
        var i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
          j: number = (j = i.length) > 3 ? j % 3 : 0;

        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t);
    }else return ''
}


export function goBack() {
    window.history.back();
}

export function getLocalDateTime(_date: number | string, format: "dd/mm/yyyy" | "dd-mm-yyyy" | "dd-mm-yyyy, hh:m_m:ss" | "dd/mm/yyyy, hh:m_m" | "yyyy-mm-dd") {
    const date = new Date(_date);
    const D = date.getDate();
    const hh = date.getHours() <  10 ? "0" + date.getHours() : date.getHours();
    const m_m = date.getMinutes() <  10 ? "0" + date.getMinutes() : date.getMinutes();
    const ss = date.getSeconds();

    const M = date.getMonth() + 1;
    const dd = D < 10 ? "0" + D : D;
    const mm = M < 10 ? "0" + M : M;
    const min = m_m === 0 ? "00" : m_m;
    const yyyy = date.getFullYear();

    let result: string = format;
    result = result.replace("D", D.toString());
    result = result.replace("M", M.toString());
    result = result.replace("dd", dd.toString());
    result = result.replace("mm", mm.toString());
    result = result.replace("yyyy", yyyy.toString());
    result = result.replace("hh", hh.toString());
    result = result.replace("m_m", min.toString());
    result = result.replace("ss", ss.toString());

    return result
}
export function validateEmail(email: any) {
    const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return pattern.test(email.trim(''))
}


export function regexPhone(e: any) {
    let value = (e.currentTarget.value + '').trim()
    value = value.replace(/[^0-9]/g, '')
    return value
}

export function convertDataSelectAnt(arr: any[]) {
    let arrNew: any = []
    arr && arr.map((val) => {
        arrNew.push({label: val?.name ?? val?.code, value: val?.id})
    });
    return arrNew
}

export function removeDiacritics(str: string) {
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
    str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
    str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
    str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
    str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
    str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
    str = str.replace(/(đ)/g, 'd');
    return str
}

export function slug(str: string) {
    let value = str ?? '';
    // Chuyển hết sang chữ thường
    value = value.toLowerCase();

    // xóa dấu
    value = value.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
    value = value.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
    value = value.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
    value = value.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
    value = value.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
    value = value.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
    value = value.replace(/(đ)/g, 'd');

    // Xóa ký tự đặc biệt
    value = value.replace(/([^0-9a-z-\s])/g, '');

    // Xóa khoảng trắng thay bằng ký tự -
    value = value.replace(/(\s+)/g, '-');

    // xóa phần dự - ở đầu
    value = value.replace(/^-+/g, '');

    // xóa phần dư - ở cuối
    value = value.replace(/-+$/g, '');

    // return
    return value;
}
